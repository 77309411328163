<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['userInfo']),
    checkPaymentStatusUrl() {
      return process.env.VUE_APP_HOTSPOT_USER_MUST_REGISTER === 'false' ? 'unregistered-customer/check-payment-status' : 'customer/check-payment-status'
    },
  },
  mounted() {
    this.checkPaymentStatus()
  },
  methods: {
    checkPaymentStatus() {
      this.isCheckingPaymentStatus = true
      axios.post(this.checkPaymentStatusUrl, {
        isManualCheck: true,
        routerId: this.userInfo.routerId,
        macAddress: this.userInfo.mac_address,
      })
        .then(response => {
          const { data } = response
          if (data.status === 'active') {
            this.showConfirmDialog = false
            this.showPaymentReceivedDialog = true
            this.loginToRouter(data.data.username, data.data.router_auth_password, this.userInfo.routerLoginLink)
          }
        })
        .catch(error => {
        // Handle errors (e.g., display a toast notification)
          console.error('Error checking payment status:', error)
        }).finally(() => {
          this.isCheckingPaymentStatus = false
        })
    },
  },
}
</script>
